<script>
import Emitter from 'element-ui/src/mixins/emitter';
import {isEmpty} from '@/commons/utils/functions';
import Errors from '@/commons/utils/Errors';
import mime from 'mime-types';
import {getFromLocal, saveInLocal} from "@/commons/utils/storage";

export default {
    name: 'PzBaseForm',
    mixins: [Emitter],
    props: {
        errors: {
            type: Errors,
            default() {
                return new Errors()
            }

        },
        inLocal: {
            type: String,
            default: null
        },

    },
    data() {
        return {
            valid: false,
            reference: 'formulario',
        }
    },
    created() {
        this.getLocal();
    },
    mounted() {
        this.$on('validate', this.handleValidate)
    },
    methods: {
        handleValidate(property, valid) {
            let dotPosition = property.indexOf(".");
            let first = property.substring(0, dotPosition);
            if (valid) {
                if (first) {
                    this.$refs[this.reference].clearValidate(first);
                }
                this.$refs[this.reference].clearValidate(property);
                this.errors.clear()
            }
        },
        handleSave(emit = true) {
            this.$store.dispatch('loader/up', {trigger: 'handle_save'});
            this.$refs[this.reference].validate((valid) => {
                if (valid) {
                    this.$nextTick(() => {
                        this.$emit('input', this.$refs[this.reference].model);
                        this.savelocal();
                        if (this.valid && emit) {
                            this.$emit('save', this.$refs[this.reference].model);
                        }
                    });
                    this.valid = true;
                } else {
                    // if (!isEmpty(this.$refs[Object.keys(invalidFields)[0]]))
                    //     this.$refs[Object.keys(invalidFields)[0]].focus();
                    this.valid = false;
                    return false;
                }
            });
           
            this.$store.dispatch('loader/down', {trigger: 'handle_save'});
            return this.valid
        },
        handleBack() {
            this.$emit('back');
        },
        resetForm() {
            this.$refs[this.reference].resetFields();
        },
        resetField(field) {
            var index = Object.keys(this.formulario1).indexOf(field);
            this.$refs[this.reference].fields[index].resetField();
        },
        addAttachment(request) {
            this.$refs[this.reference].model[request.data.fild] = request.file;
            this.$refs[this.reference].model[request.data.fild]['icon'] = this.getFileTypeExtencions(request.file.type);
        },
        getFileTypeExtencions(fileType) {
            return mime.extension(fileType)
        },
        getFileExtencionsType(fileType) {
            return mime.extension(fileType)
        },
        savelocal() {
            if (!isEmpty(this.inLocal)) {
                saveInLocal(this.inLocal, this.$refs[this.reference].model);
            }
        },
        getLocal() {
            if (!isEmpty(this.inLocal)) {
                this.$store.dispatch('loader/up', {trigger: 'get_local'});
                try {
                    let data = getFromLocal(this.inLocal);
                    if (!isEmpty(data)) {
                        this[this.reference] = data;
                    }
                    let data_client = getFromLocal('data_client');
                    if (!isEmpty(data_client) && data_client['cliente']=='fenoreste') {
                        this[this.reference] = data_client;
                    }
                    this.$store.dispatch('loader/down', {trigger: 'get_local'});
                } catch
                    (error) {
                    this.$store.dispatch('loader/down', {trigger: 'get_local'});
                }
            }
        },
    },

}
</script>


